import React, {useContext, useEffect, useState} from "react";
import {Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
import {Tooltip, notification} from "antd";
// core
import {IPhone} from "../../../../../../core/models/Phones";
import {LayoutContext, ModalContext} from "../../../../../../core/Contexts";
import {IPhonesFiltersRequest} from "../../../../../../core/models/PhonesFilters";
// services
import PhoneService from "../../../../../../services/PhoneService";
import PhonesDefaultService from "../../../../../../services/PhonesDefaultService";
import ReservationsService from "../../../../../../services/ReservationsService";
import PhonesFiltersService from "../../../../../../services/PhonesFiltersService";
// components
import Paid from "./paid";
import Free from "./free";
import Internet from "./internet";
import lightning2 from "../../../../../../images/lightning2.svg";
import npsImg from "../../../../../../images/nps_notif.svg";
import Error from "../../../../../modal/error";
import PurchaseOfPaidNumbers from "../../../../../modal/purchaseOfPaidNumbers";
import UserService from "../../../../../../services/UserService";
import ButtonChatSupport from "../../../../delivery/buttonChatSupport";
// store
import Store from "../../../../../../stores";
import superPng from "../../../../../../images/superbrone.svg";
import {createSearchParams, useNavigate} from "react-router-dom";
import PhonesBookingService from "../../../../../../services/PhonesBookingService";

export default observer(function Actions({
    Service,
    filters,
    props: {
        phone,
        type,
        mask: {category},
        tariff: {id, name: tariffName, minutes, internet, sms},
        priceParams: {mask_price}
    },
    search
}: IProps) {
    const contextModal = useContext(ModalContext);
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const [state, setState] = useState<string>("3 часа");
    const navigate = useNavigate();
    const el: any = document.body.querySelector(".ant-notification-notice-close");

    const personalManagerPhone = UserService.hasPersonalManager
        ? UserService.personalManager.phone
        : "";

    const onClickWhatsApp = () => {
        (window.open(`https://wa.me/7${personalManagerPhone}`, "_blank") as any).focus();
    };

    const onClickPaid = () => {
        contextModal?.setChildren(<PurchaseOfPaidNumbers phone={phone} priceParams={mask_price} />);
        contextModal?.setVisible(true);
    };

    const onClickSuper = async () => {
        navigate({
            pathname: `/reservation/${id}/${phone}`,
            search: createSearchParams({
                super: "true"
            }).toString()
        });
    };

    const onClick = async () => {
        try {
            const filter =
                localStorage.getItem("isFavorite") === "collection"
                    ? "collection"
                    : localStorage.getItem("isFavorite") === "true"
                    ? "favorite"
                    : "professional";
            await ReservationsService.postReservationTurbo({
                phone: phone.toString(),
                loading: true,
                additional_data: JSON.stringify({
                    source: "store_web",
                    filter,
                    ...(Store.nps.phone ? {nps: {phone: +Store.nps.phone}} : {})
                })
            });
            if (el) {
                el.click();
            }
            Service.updatePhones(PhonesFiltersService.getFormatedFilters(filters));
            notification.open({
                message: t("Номер успешно забронирован"),
                description: t(
                    `Номер забронирован на ${state}, \nперейдите на вкладку "Брони и активации"`
                ),
                placement: "bottom",
                closeIcon: undefined,
                className: "turbo-referral",
                duration: 1
            });
        } catch (e: any) {
            if (_.isEqual(e.response.status, 430)) {
                contextModal?.setChildren(
                    <Error
                        title="Вы превысили лимит"
                        message={
                            <Space size={16} direction="vertical">
                                {_.isEqual(UserService.level.level, 0) ? (
                                    <>
                                        <Typography as="div" size={14} color="#000000">
                                            {t("Повторите попытку завтра.")}
                                            <br />
                                            {t("Чтобы увеличить лимит обратитесь в чат")}
                                        </Typography>
                                        <ButtonChatSupport type="primary" text="Написать в чат" />
                                    </>
                                ) : (
                                    <>
                                        <Typography as="div" size={14} color="#000000">
                                            {t("Повторите попытку завтра. Чтобы увеличить лимит")}
                                            <br />
                                            {t("обратитесь к персональному менеджеру")}
                                        </Typography>
                                        <Button onClick={onClickWhatsApp} type="primary">
                                            {t("Написать в WhatsApp")}
                                        </Button>
                                    </>
                                )}
                            </Space>
                        }
                    />
                );
            } else {
                contextModal?.setChildren(<Error />);
            }
            contextModal?.setVisible(true);
        }
    };

    useEffect(() => {
        let name: string;

        switch (type) {
            case 4:
                name = "1 день";
                break;
            case 5:
                name = "5 дней";
                break;
            default:
                name = "3 часа";
                break;
        }

        setState(name);
    }, [type]);

    return (
        <Typography
            as="div"
            className={`bl-numbers-list-actions${
                PhonesDefaultService.isPaid(type) ? " is-paid" : ""
            } ${
                UserService.storeCanBookForDelivery && !PhonesBookingService.isPaid(type)
                    ? ""
                    : " without-left"
            }`}
            onClick={() => {
                Store.reservations.photosFirst = "";
                Store.reservations.photosSecond = "";
            }}
        >
            <Space
                size={
                    _.gte(contextLayout?.width ?? 0, 768)
                        ? UserService.storeCanBookForDelivery
                            ? 6
                            : 16
                        : UserService.storeCanBookForDelivery
                        ? 6
                        : 8
                }
                direction="horizontal"
            >
                {PhonesDefaultService.isPaid(type) && <Paid maskPrice={mask_price} />}
                {PhonesDefaultService.isFree(type) && !_.isEqual(type, 6) && (
                    <Free phone={phone} type={type} id={id} search={search} />
                )}
                {PhonesDefaultService.isInternet(type) && (
                    <Internet phone={phone} type={type} id={id} />
                )}
                {PhonesDefaultService.isPaid(type) ? (
                    <Typography
                        onClick={onClickPaid}
                        className={`wantBuy${
                            _.gt(_.size(mask_price.toString()), 6) ? " absolute" : ""
                        }`}
                    >
                        {t("Хочу купить")}
                    </Typography>
                ) : (
                    <Typography
                        as="div"
                        className="bl-reservation-list-info-actions-tooltip-container"
                        id={`tooltip-container-${phone}`}
                    >
                        <Tooltip
                            getPopupContainer={() => {
                                return document.getElementById(`tooltip-container-${phone}`)!;
                            }}
                            overlayClassName="toltip-reservation"
                            title={"Турбо-бронь"}
                        >
                            <Button type="primary" className="btn-turbo" onClick={onClick}>
                                {Store.nps.phone ? (
                                    <img src={npsImg} alt="" width={32} />
                                ) : (
                                    <img src={lightning2} width={32} alt="" />
                                )}
                            </Button>
                        </Tooltip>
                    </Typography>
                )}
                {UserService.storeCanBookForDelivery && !PhonesBookingService.isPaid(type) && (
                    <Typography
                        as="div"
                        className="bl-reservation-list-info-actions-tooltip-container"
                        id={`tooltip-container-${phone}`}
                    >
                        <Tooltip
                            getPopupContainer={() => {
                                return document.getElementById(`tooltip-container-${phone}`)!;
                            }}
                            overlayClassName="toltip-reservation"
                            title={"Супербронь"}
                        >
                            <div className="gold-bg icon-wrapper lightning" onClick={onClickSuper}>
                                <img src={superPng} alt="" width={32} />
                            </div>
                        </Tooltip>
                    </Typography>
                )}
            </Space>
        </Typography>
    );
});

interface IProps {
    filters: IPhonesFiltersRequest;
    props: IPhone;
    Service: PhoneService;
    search: URLSearchParams;
}
