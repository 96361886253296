import {Button, Drawer, Modal, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import npsImg from "../../../../../../../images/npsreser3.svg";
import npsEditImg from "../../../../../../../images/npsedit.svg";
import npsRemoveImg from "../../../../../../../images/npsremove.svg";
import emptyImg from "../../../../../../../images/empty-result.png";
import {useContext, useState} from "react";
import {LayoutContext} from "../../../../../../../core/Contexts";
import _ from "lodash";
import Store from "../../../../../../../stores";

export default function Edit({phone, setNpsPhone, setEdit, setRemoved}: IProps) {
    const [open, setOpen] = useState(false);
    const contextLayout = useContext(LayoutContext);

    const onClose = () => {
        setOpen(false);
    };

    const onRemove = () => {
        onClose();
        setTimeout(() => {
            setNpsPhone(undefined);
            setRemoved(true);
        }, 200);
    };

    return (
        <Typography as="div" className="bl-nps-reservation-edit">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="35"
                viewBox="0 0 48 35"
                fill="none"
            >
                <path
                    d="M3 15.4506L18.3243 31.2586L45 3.74133"
                    stroke="#27F548"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <Typography as="div" className="phones">
                <img src={npsImg} height={26} alt="" />
                <Typography as="div" className="phone">
                    {phone}
                </Typography>
            </Typography>
            <Typography as="div" className="actions">
                <img
                    src={npsEditImg}
                    height={24}
                    alt=""
                    onClick={() => {
                        setEdit(true);
                        Store.nps.phone = null;
                    }}
                />
                <img
                    src={npsRemoveImg}
                    height={24}
                    alt=""
                    onClick={() => {
                        setOpen(true);
                    }}
                />
            </Typography>

            {_.gt(contextLayout?.width, 768) ? (
                <Drawer
                    width={634}
                    className="bl-nps-reservation-drawer"
                    placement="right"
                    destroyOnClose={true}
                    onClose={onClose}
                    open={open}
                    maskClosable={false}
                    title={false}
                    closeIcon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                        >
                            <path
                                d="M29 1L15.0458 15M15.0458 15L1 29M15.0458 15L1 1M15.0458 15L29 29"
                                stroke="#E5E5E5"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </svg>
                    }
                >
                    <Typography as="div" className="empty-result">
                        <img src={emptyImg} alt="" />
                        <Typography
                            as="div"
                            className="empty-title"
                        >{`Вы действительно хотите\nудалить номер?`}</Typography>
                        <Typography as="div" className="empty-actions">
                            <Button type="primary" onClick={onRemove}>
                                Удалить
                            </Button>
                            <Button onClick={onClose}>Закрыть</Button>
                        </Typography>
                    </Typography>
                </Drawer>
            ) : (
                <Modal
                    className="bl-nps-reservation-drawer"
                    destroyOnClose={true}
                    onCancel={onClose}
                    open={open}
                    maskClosable={false}
                    title={false}
                    maskStyle={{
                        background:
                            "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(0deg, rgba(100, 40, 120, 0.65) 0%, rgba(100, 40, 120, 0.65) 100%), rgba(255, 255, 255, 0.05)"
                    }}
                    closeIcon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="21"
                            viewBox="0 0 22 21"
                            fill="none"
                        >
                            <path
                                d="M21 0.5L11.0327 10.5M11.0327 10.5L1 20.5M11.0327 10.5L1 0.5M11.0327 10.5L21 20.5"
                                stroke="white"
                                stroke-linecap="round"
                            />
                        </svg>
                    }
                >
                    <Typography as="div" className="empty-result">
                        <img src={emptyImg} alt="" />
                        <Typography
                            as="div"
                            className="empty-title"
                        >{`Вы действительно\nхотите удалить номер?`}</Typography>
                        <Typography as="div" className="empty-actions">
                            <Button type="primary" onClick={onRemove}>
                                Удалить
                            </Button>
                            <Button onClick={onClose}>Закрыть</Button>
                        </Typography>
                    </Typography>
                </Modal>
            )}
        </Typography>
    );
}

interface IProps {
    phone: string;
    setNpsPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    setRemoved: React.Dispatch<React.SetStateAction<boolean>>;
}
